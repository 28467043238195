import React, {useEffect} from "react";
import {useOutlet} from "react-router-dom";

import Util from "../../../../../nowui-common/js/util";

import Home from "../home/index";

import Image0 from "../../image/0.png";
import Image7 from "../../image/7.png";
import Image24 from "../../image/24.png";

const Main = ({...props}) => {
    const outlet = useOutlet();

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div className="header">
                <img
                    className="header_name"
                    src={Image0}
                    onClick={() => {
                        Util.navigateTo(props, {
                            url: "/",
                        });
                    }}
                />
                <div className="header_menu">
                    <div
                        className={props.location.pathname == "/meta" ? "header_menu_item_active" : "header_menu_item"}
                        onClick={() => {
                            Util.navigateTo(props, {
                                url: "/meta",
                            });
                        }}
                    >
                        低碳树成长纪
                    </div>
                    <div
                        className={props.location.pathname == "/solution" ? "header_menu_item_active" : "header_menu_item"}
                        onClick={() => {
                            Util.navigateTo(props, {
                                url: "/solution",
                            });
                        }}
                    >
                        能源解决方案
                    </div>
                    <div
                        className={
                            props.location.pathname == "/shop" || props.location.pathname.indexOf("/goods/") > -1
                                ? "header_menu_item_active"
                                : "header_menu_item"
                        }
                        onClick={() => {
                            Util.navigateTo(props, {
                                url: "/shop",
                            });
                        }}
                    >
                        积分商城
                    </div>
                    <div
                        className={props.location.pathname == "/about" ? "header_menu_item_active" : "header_menu_item"}
                        onClick={() => {
                            Util.navigateTo(props, {
                                url: "/about",
                            });
                        }}
                    >
                        关于我们
                    </div>
                    <div
                        className="header_menu_item"
                        onClick={() => {
                            Util.navigateTo(props, {
                                url: "/",
                            });
                        }}
                    >
                        深圳市炬能低碳科技有限公司
                    </div>
                </div>
                <div className="header_join">加入我们</div>
            </div>
            {outlet}
            <div className="footer">
                <div className="wrapper">
                    <img src={Image7} />
                    <div
                        style={{
                            width: "100%",
                            maxWidth: "1320px",
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "10px",
                            borderBottom: "1px solid #9E9E9E",
                        }}
                    >
                        <img src={Image24} />
                        <div
                            style={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                borderLeft: "1px solid #9E9E9E",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    lineHeight: "36px",
                                    color: "#9E9E9E",
                                }}
                            >
                                联系方式：15814032700
                            </div>
                            <div
                                style={{
                                    fontSize: "16px",
                                    lineHeight: "36px",
                                    color: "#9E9E9E",
                                }}
                            >
                                地址：深圳市南山区南海大道1117号数码大厦A座二楼206E
                            </div>
                        </div>
                    </div>
                    <a
                        style={{
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "100px",
                            textDecorationLine: "none",
                            color: "#979797",
                        }}
                        href="http://beian.miit.gov.cn/"
                        target="_blank"
                    >
                        粤ICP备2024311521号
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

Main.propTypes = {};

export default Util.withRouter(Main);
