import React, {useState, useRef, useEffect} from "react";
import ReactDOM from "react-dom/client";
import {legacy_createStore, combineReducers} from "redux";
import {Provider} from "react-redux";
import {routerReducer} from "react-router-redux";
import {HashRouter, Route, Routes} from "react-router-dom";

import Main from "./view/main";
import Home from "./view/home";

import "@arco-design/web-react/dist/css/index.less";
import "./index.css";

const reducers = {};
let storeContexts = require.context("./view/", true, /^.*\/store\.jsx$/);
storeContexts.keys().forEach((fileName) => {
    reducers[storeContexts(fileName).default.name] = storeContexts(fileName).default.reducer;
});

let routers = [];
let routerContexts = require.context("./view/", true, /^.*\/router\.jsx$/);
routerContexts.keys().forEach((fileName) => {
    routers = routers.concat(routerContexts(fileName).default);
});

const stores = legacy_createStore(
    combineReducers(
        Object.assign(reducers, {
            routing: routerReducer,
        })
    )
);

const App = ({...props}) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <Provider store={stores}>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<Main />}>
                            <Route path="/" element={<Home />} />
                            {routers.map((router) => {
                                return router;
                            })}
                        </Route>
                    </Routes>
                </HashRouter>
            </Provider>
        </React.Fragment>
    );
};

let root;
function render(props) {
    const {container} = props;
    root = ReactDOM.createRoot(container ? container.querySelector("#root") : document.querySelector("#root"));
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
}

render({});
