import React, {useEffect} from "react";

import Util from "../../../../../nowui-common/js/util";

import Image17 from "../../image/17.png";
import Image18 from "../../image/18.png";
import Image19 from "../../image/19.png";
import Image20 from "../../image/20.png";
import Image21 from "../../image/21.png";
import Image22 from "../../image/22.png";
import Image23 from "../../image/23.png";

const About = ({...props}) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div className="row_0">
                <div className="wrapper">
                    <img src={Image17} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "50px",
                }}
            >
                <div className="wrapper">
                    <img src={Image18} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "50px",
                }}
            >
                <div className="wrapper">
                    <img src={Image19} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "50px",
                }}
            >
                <div className="wrapper">
                    <img src={Image20} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "100px",
                }}
            >
                <div className="wrapper">
                    <img src={Image21} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "100px",
                }}
            >
                <div className="wrapper">
                    <img src={Image22} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <div className="wrapper">
                    <img src={Image23} />
                </div>
            </div>
        </React.Fragment>
    );
};

About.propTypes = {};

export default Util.withRouter(About);
