import React, {useEffect} from "react";
import ReactPlayer from "react-player";

import Util from "../../../../../nowui-common/js/util";

import Image5 from "../../image/5.png";
import Image6 from "../../image/6.png";
import Image8 from "../../image/8.png";
import Image9 from "../../image/9.png";

const About = ({...props}) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div
                className="row_0"
                style={{
                    marginTop: "18px",
                }}
            >
                <div className="wrapper">
                    <img src={Image5} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "18px",
                }}
            >
                <div className="wrapper">
                    <ReactPlayer url={"https://file.ditanmeta.com/1833770423807602688/0.mp4"} width="100%" controls onEnded={() => {}} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "50px",
                }}
            >
                <div className="wrapper">
                    <img src={Image6} />
                </div>
            </div>
            <div className="row_0">
                <div
                    style={{
                        width: "1206px",
                        margin: "0 auto",
                        marginTop: "50px",
                    }}
                >
                    <img src={Image8} />
                </div>
            </div>
            <div
                className="row_1"
                style={{
                    marginTop: "50px",
                }}
            >
                <div className="wrapper">
                    <img src={Image9} />
                </div>
            </div>
        </React.Fragment>
    );
};

About.propTypes = {};

export default Util.withRouter(About);
