import React, {useEffect} from "react";

import Util from "../../../../../nowui-common/js/util";

import Image10 from "../../image/10.png";
import Image11 from "../../image/11.png";
import Image12 from "../../image/12.png";
import Image13 from "../../image/13.png";
import Image14 from "../../image/14.png";
import Image15 from "../../image/15.png";
import Image16 from "../../image/16.png";

const Meta = ({...props}) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div
                className="row_0"
                style={{
                    height: "752px",
                    marginTop: "150px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#165a4a",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            width: "1206px",
                            margin: "0 auto",
                        }}
                    >
                        <img src={Image10} />
                        <img
                            style={{
                                position: "absolute",
                                top: "-59px",
                                right: 0,
                            }}
                            src={Image11}
                        />
                        <img
                            style={{
                                position: "absolute",
                                top: "-59px",
                                left: 0,
                            }}
                            src={Image12}
                        />
                    </div>
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    height: "752px",
                    marginTop: "150px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#165a4a",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            width: "1206px",
                            margin: "0 auto",
                        }}
                    >
                        <img src={Image13} />
                        <img
                            style={{
                                position: "absolute",
                                top: "-59px",
                                left: 0,
                            }}
                            src={Image14}
                        />
                    </div>
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    height: "752px",
                    marginTop: "150px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#165a4a",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            width: "1206px",
                            margin: "0 auto",
                        }}
                    >
                        <img src={Image15} />
                        <img
                            style={{
                                position: "absolute",
                                top: "-59px",
                                right: 0,
                            }}
                            src={Image16}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

Meta.propTypes = {};

export default Util.withRouter(Meta);
