import React, {useState, useEffect} from "react";
import axios from "axios";

import Util from "../../../../../nowui-common/js/util";

import Image29 from "../../image/29.png";
import Image30 from "../../image/30.png";

const Shop = ({...props}) => {
    const controller = new AbortController();
    const [goodsList, setGoodsList] = useState([]);

    useEffect(() => {
        axios({
            method: "post",
            url: process.env.API_URL + "/goods/frontend/website/v1/list",
            signal: controller.signal,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "",
                Locale: "",
            },
            data: {
                pageIndex: 1,
                pageSize: 10,
            },
        })
            .then((response) => {
                if (response.data.code === 0) {
                    setGoodsList(response.data.data);
                }
            })
            .catch(function (error) {});

        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div
                className="row_0"
                style={{
                    marginTop: "18px",
                }}
            >
                <div className="wrapper">
                    <img src={Image29} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "180px",
                }}
            >
                <div className="wrapper">
                    <img src={Image30} />
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "50px",
                }}
            >
                <div className="wrapper">
                    <div
                        style={{
                            width: "1126px",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridRowGap: "46px",
                            gridColumnGap: "46px",
                        }}
                    >
                        {goodsList.map((goods, goodsIndex) => {
                            return (
                                <div
                                    key={goodsIndex}
                                    style={{
                                        width: "540px",
                                        borderRadius: "30px",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        props.navigate("/goods/" + goods.goodsId);
                                    }}
                                >
                                    <img
                                        style={{
                                            width: "540px",
                                            height: "540px",
                                        }}
                                        src={process.env.FILE_URL + goods.goodsImage}
                                    />
                                    <div
                                        style={{
                                            padding: "20px",
                                            backgroundColor: "#18a88d",
                                            color: "#ffffff",
                                            fontSize: "18px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        <div
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {goods.goodsName}
                                        </div>
                                        <div>
                                            {goods.goodsPrice}元 + {goods.goodsPrice}金豆
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div
                style={{
                    height: "180px",
                }}
            ></div>
        </React.Fragment>
    );
};

Shop.propTypes = {};

export default Util.withRouter(Shop);
