import React, {useState, useEffect} from "react";
import axios from "axios";

import Util from "../../../../../nowui-common/js/util";

import Image29 from "../../image/29.png";
import Image30 from "../../image/30.png";

const Goods = ({...props}) => {
    const controller = new AbortController();
    const [goods, setGoods] = useState({});

    useEffect(() => {
        axios({
            method: "post",
            url: process.env.API_URL + "/goods/frontend/website/v1/item",
            signal: controller.signal,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "",
                Locale: "",
            },
            data: {
                goodsId: props.params.goodsId,
            },
        })
            .then((response) => {
                if (response.data.code === 0) {
                    setGoods(response.data.data);
                }
            })
            .catch(function (error) {});

        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div
                className="row_0"
                style={{
                    marginTop: "18px",
                }}
            >
                <div className="wrapper">
                    <div
                        style={{
                            width: "1126px",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridRowGap: "46px",
                            gridColumnGap: "46px",
                        }}
                    >
                        <div
                            style={{
                                width: "540px",
                            }}
                        >
                            <img
                                style={{
                                    width: "540px",
                                    height: "540px",
                                    borderRadius: "30px",
                                    overflow: "hidden",
                                }}
                                src={process.env.FILE_URL + goods.goodsImage}
                            />
                        </div>
                        <div
                            style={{
                                width: "540px",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "60px",
                                    marginTop: "116px",
                                }}
                            >
                                {goods.goodsName}
                            </div>
                            <div
                                style={{
                                    fontSize: "50px",
                                    color: "#18a88d",
                                }}
                            >
                                {goods.goodsPrice}元 + {goods.goodsPrice}金豆
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row_0"
                style={{
                    marginTop: "180px",
                }}
            >
                <div className="wrapper">
                    <div dangerouslySetInnerHTML={{__html: goods.goodsIntroduction}} />
                </div>
            </div>
            <div
                style={{
                    height: "180px",
                }}
            ></div>
        </React.Fragment>
    );
};

Goods.propTypes = {};

export default Util.withRouter(Goods);
